<template>
	<div data-component="deal-library">
		<V2Actions
			v-if="isDebugMode"
			:actions="getActions"
			@actionClick="onActionClick"
		/>
		<deal-list
			v-if="getGroups"
			:groups="getGroups"
			:filters="getFilterOptions"
			:pagination="getPagination"
			:totalItems="getTotalItems"
			:showClearFilters="true"
			:isInActivity="isInActivity"
			@search="onSearch"
			@filter="onFilter"
			@paginate="onPaginate"
		/>
		<portal
			to="modal"
			v-if="deleteConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleDeleteConfirmation"
				heading="Delete all deals"
			>
				<confirmation-modal
					text="Deleting all deals will permanently remove every deal from the deal library. Deleted deals will still be available in deal run throughs."
					confirmationText="Delete"
					confirmationActionId="deleteAllDeals"
					cancelActionId="toggleDeleteConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import V2Actions            from '@/components/v2/ui/V2Actions';
	import DealList           from '@/components/dealLibrary/DealList';
	import ModalInner         from '@/components/ui/modal/ModalInner';
	import ConfirmationModal  from '@/components/ui/modal/ConfirmationModal';
	import actionClick        from '@/mixins/actionClick';
	import api                from '@/services/api';

	export default {
		metaInfo: {
			title: 'Deal library'
		},
		components: {
			V2Actions,
			DealList,
			ModalInner,
			ConfirmationModal
		},
		mixins: [
			actionClick
		],
		props: {
			isInActivity: {
				type: Boolean,
				default: false
			},
			api: {
				type: String,
				default: 'dealLibrary'
			}
		},
		data: () => ({
			tags:                         [],
			teachers:                     [],
			names:                        [],
			isDebugMode:                  window.location.href.includes('?debug=true'),
			deleteConfirmationIsVisible:  false
		}),
		computed: {
			getGroups () {
				return this.$store.getters['dealLibrary/getGroups'];
			},
			getActiveFilters () {
				return this.$store.getters['dealLibrary/getActiveFilters'];
			},
			getPagination () {
				return this.$store.getters['dealLibrary/getPagination'];
			},
			getTotalItems () {
				return this.$store.getters['dealLibrary/getTotalItems'];
			},
			getActions () {
				return {
					secondary: [
						(this.isDebugMode ?
							{
								text: 'Delete all deals',
								actionId: 'toggleDeleteConfirmation'
							} : false
						)
					]
				};
			},
			getFilterOptions () {
				return this.$store.getters['dealLibrary/getFilterOptions'];
			},
			getBreadcrumbs () {
				return [
					{
						path: '/teaching',
						text: 'Teaching'
					},
					{
						path: '/teaching/deals',
						text: 'Deal library'
					}
				];
			}
		},
		created () {
			this.checkPending();
		},
		methods: {
			async setGroups () {
				await this.$store.dispatch('dealLibrary/setGroups');
				if (!this.isInActivity) {
					this.$store.commit('ui/setBreadcrumbs', {
						breadcrumbs: this.getBreadcrumbs
					});
				}
				this.$store.commit('ui/setLoadingIsHidden');
			},
			onFilter (value) {
				this.$store.commit('dealLibrary/setCurrentPage', {
					currentPage: 1
				});
				this.$store.commit('dealLibrary/setActiveFilters', {
					activeFilters: value
				});
				this.setGroups();
			},
			onSearch (value) {
				this.$store.commit('dealLibrary/setSearchString', {
					searchString: value
				});
				this.setGroups();
			},
			onPaginate (value) {
				this.$store.commit('dealLibrary/setCurrentPage', {
					currentPage: value
				});
				this.setGroups();
			},
			async checkPending () {
				const response = await api.deals.checkPending();
				if (response && response.length > 0) {
					setTimeout(() => {
						this.$store.commit('ui/showNotification', {
							notification: 'You have pending imports in progress. You can use the library as normal and these will appear shortly.'
						});
					}, 3000);
				}
			},
			toggleDeleteConfirmation () {
				this.deleteConfirmationIsVisible = !this.deleteConfirmationIsVisible;
			},
			async deleteAllDeals () {
				this.toggleDeleteConfirmation();
				const response = await api.deals.deleteAllDeals();
				if (!response) {
					this.$store.commit('ui/showError');
					return false;
				}
				await this.$router.push('/teaching');
				this.$store.commit('ui/showNotification', {
					notification: 'All deals have been removed'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='deal-library'] {
		margin:rem(24) 0 0;
	}
</style>
