<template>
	<div data-component="group">
		<div data-element="group-wrapper">
			<p data-element="group-name">
				{{ group.groupName }}
			</p>
			<div data-element="actions">
				<slot
					v-if="hasGroupActionsSlot"
					name="groupActions"
					:group="group"
				/>
				<V2Button
					fit
					:to="getEditRoute"
					:route="getEditRoute"
					@click="toggleDealsAreVisible"
				>
					Edit
				</V2Button>
				<V2Button
					:text="getToggleBtnText"
					@click="toggleDealsAreVisible"
					fit
				>
					{{ getToggleBtnText }}
				</V2Button>
			</div>
		</div>
		<div
			v-if="getDeals && dealsAreVisible"
			data-element="deals"
		>
			<deal
				v-for="(deal, key) in getDeals"
				:key="key"
				:deal="deal"
				:isInActivity="isInActivity"
			>
				<template #actions="{ deal }">
					<slot name="actions" :deal="deal" />
				</template>
			</deal>
		</div>
	</div>
</template>

<script>

	import Deal  from '@/components/dealLibrary/Deal';
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		components: {
			Deal,
			V2Button
		},
		props: {
			group: {
				type: Object,
				required: true
			},
			isInActivity: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			dealsAreVisible: false
		}),
		computed: {
			getDeals () {
				return this.group?.deals;
			},
			getNumberOfDeals () {
				return this.getDeals?.length;
			},
			getToggleBtnText () {
				if (!this.dealsAreVisible) {
					return `Show ${this.getNumberOfDeals} deals`;
				}
				return 'Hide deals';
			},
			getEditRoute () {
				return `/teaching/deals/groups/${this.group.groupId}`;
			},
			hasGroupActionsSlot () {
				return !!this.$scopedSlots.groupActions;
			}
		},
		methods: {
			toggleDealsAreVisible () {
				this.dealsAreVisible = !this.dealsAreVisible;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='group'] {
		margin-bottom:rem(8);

    padding: 20px;
    background: $c-white-l;
    border-radius:4px;

		[data-element='group-wrapper'] {
			display:flex;
			align-items:center;
			padding:rem(10) 0;

			[data-element='group-name'] {
				margin-right:rem(24);

        @include font(18px, $c-dark-navy, 800, 23px);
			}

			[data-element='actions'] {
				display:flex;
				margin:auto 0 auto auto;
				align-items: center;
        gap: 12px;
			}
		}
	}

</style>
