<template>
	<div class="deal-list">
		<div class="controls">
			<search
				v-if="showSearch"
				@search="onSearch"
				inputPlaceholder="Search by deal name"
			/>

			<filters
				:filters="filters"
				@filter="onFilter"
			/>
		</div>

		<div
			data-element="list"
			v-if="groups"
		>
			<deal-list-item
				v-for="(group, key) in groups"
				:key="key"
				:group="group"
				:isInActivity="isInActivity"
			/>
		</div>

		<records-count
			:pagination="pagination"
			:total="totalItems"
		/>
		<pagination
			v-if="showPagination"
			v-bind="pagination"
			@paginate="onPaginate"
		/>
	</div>
</template>

<script>

	import DealListItem  from '@/components/dealLibrary/DealListItem';
	import Search        from '@/components/ui/views/SearchInput';
	import Filters       from '@/components/ui/views/filters/Filters';
	import Pagination    from '@/components/ui/views/Pagination';
	import RecordsCount  from '@/components/ui/views/RecordsCount';

	export default {
		components: {
			Search,
			Filters,
			DealListItem,
			Pagination,
			RecordsCount
		},
		props: {
			showSearch: {
				type: Boolean,
				default: true
			},
			showPagination: {
				type: Boolean,
				default: true
			},
			filters: {
				type: Array,
				default: () => []
			},
			groups: {
				type: Array,
				default: undefined
			},
			pagination: {
				type: Object,
				default: () => ({})
			},
			totalItems: {
				type: Number,
				default: undefined
			},
			isInActivity: {
				type: Boolean,
				default: false
			}
		},

		data: () => {
			return {
				test: ''
			};
		},

		methods: {
			onSearch (value) {
				this.$emit('search', value);
			},
			onFilter (value) {
				this.$emit('filter', value);
			},
			onPaginate (value) {
				this.$emit('paginate', value);
			}
		}
	};

</script>

<style lang="scss" scoped>

	.deal-list {
		margin-bottom:32px;
		width: 100%;

		.controls {
      display: flex;
      flex-wrap: wrap;

			[data-component='filters'] {
				margin-bottom:12px;
			}
		}

		[data-component='pagination'] {
			margin-top:12px;
		}

		[data-highlight] {
			// modify this to something better when we do UI
			opacity: 0.5;
		}
  }

</style>
