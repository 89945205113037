<template>
	<div class="container page">
		<div class="page__info">
			<div class="page__info--title">
				Browse deals
			</div>
			<V2Button class="page__info--btn" to="/teaching/deals/create">
				Import PBN
			</V2Button>
		</div>

		<div class="deal-library">
			<deal-library :isInActivity="false" />
		</div>
	</div>
</template>

<script>
	import DealLibrary from '@/components/dealLibrary/DealLibrary';
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		metaInfo: {
			title: 'Deal library'
		},

		layout: 'v2default',

		components: {
			DealLibrary,
			V2Button
		},
		computed: {
			getPageHeaderAction () {
				return {
					text: 'Import PBN',
					route: '/teaching/deals/create'
				};
			}
		}
	};
</script>

<style lang="scss" scoped>
.page {
  &__info {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    &--title {
      width: fit-content;
    }

    &--btn {
      width: fit-content;
    }
  }
}
</style>
