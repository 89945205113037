<template>
	<div
		v-if="getShowContractAndDeclarer"
		data-component="contract-and-declarer"
		:data-size="getSize"
	>
		<span
			data-element="contract-value"
			v-html="getContractValue"
		/>
		<suit-symbol
			:suitName="getContractSuit"
			:withoutPadding="true"
		/>
		<span
			data-element="declarer"
			v-if="getDeclarerString"
			v-html="getDeclarerString"
		/>
	</div>
</template>

<script>

	import SuitSymbol from '@/components/diagrams/SuitSymbol';

	export default {
		components: {
			SuitSymbol
		},
		props: {
			contract: {
				type: String,
				default: undefined
			},
			declarer: {
				type: String,
				default: undefined
			},
			isPartOfSimulator: {
				type: Boolean,
				default: false
			},
			isDoubled: {
				type: Boolean,
				default: false
			},
			isReDoubled: {
				type: Boolean,
				default: false
			},
			size: {
				type: String,
				default: 'md'
			}
		},
		computed: {
			getShowContractAndDeclarer () {
				if (!this.getContractValue ||
						!this.getContractSuit ||
						!this.getDeclarer) {
					return false;
				}
				return true;
			},
			getContractValue () {
				if (this.isPartOfSimulator) {
					return this.$store.getters['simulator/getContractValue'];
				}
				if (!this.contract) {
					return false;
				}

        let val;
				if (this.contract[1] === 'N') {
					val = `${this.contract[0]}NT ${this.isReDoubled ? 'XX' : this.isDoubled ? 'X' : ''}`;
				} else {
          val = `${this.contract[0]} ${this.isReDoubled ? 'XX' : this.isDoubled ? 'X' : ''}`;
        }

        return val;
			},
			getContractSuit () {
				if (this.isPartOfSimulator) {
					return this.$store.getters['simulator/getContractSuit'];
				}
				if (!this.contract) {
					return false;
				}
				return this.contract[1];
			},
			getDeclarer () {
				if (this.isPartOfSimulator) {
					return this.$store.getters['simulator/getDeclarer'];
				}
				if (!this.declarer) {
					return false;
				}
				return this.declarer;
			},
			getDeclarerString () {
				if (!this.getDeclarer) {
					return false;
				}
				return `&nbsp;by ${this.getDeclarer}`;
			},
			getSize () {
				return this.size;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='contract-and-declarer'] {
		display:inline-flex;
		align-items:center;
		margin:0;
		[data-element='contract-value'] {
			font-size:rem(14);
			line-height:1;
			font-weight:700;
		}
		::v-deep [data-component='suit-symbol'] {
			margin-left:0.1em;
			[data-component='icon'] {
				width:auto; height:rem(10);
			}
		}
		[data-element='declarer'] {
			font-size:rem(14);
			line-height:1;
			font-weight:700;
		}
		&[data-size='lg'] {
			[data-element='contract-value'] {
				font-size:rem(18);
				line-height:1;
				font-weight:600;
			}
			::v-deep [data-component='suit-symbol'] {
				[data-component='icon'] {
					width:auto; height:rem(14);
				}
			}
			[data-element='declarer'] {
				font-size:rem(18);
				line-height:1;
				font-weight:600;
			}
		}
	}

</style>
