<template>
	<div
		data-component="pagination"
		v-if="showPagination"
	>
		<V2Button
			v-if="getShowFirstPage"
			fit
			@click="paginate(getFirstPage)"
		>
			First
		</V2Button>
		<V2Button
			v-if="getPreviousPage"
			fit
			@click.native="paginate(getPreviousPage)"
		>
			Previous
		</V2Button>

		<div data-element="pages">
			<div
				data-element="page"
				v-for="(page, key) in getSurroundingPages"
				:key="key"
				:data-current="getIsCurrentPage(page)"
				@click="paginate(page)"
			>
				{{ page }}
			</div>
		</div>
		<V2Button
			v-if="getNextPage"
			fit
			@click="paginate(getNextPage)"
		>
			Next
		</V2Button>
		<V2Button
			v-if="getShowLastPage"
			fit
			@click="paginate(getLastPage)"
		>Last</V2Button>
	</div>
</template>

<script>
	import V2Button           from '@/components/v2/ui/V2Button';

	export default {
		components: {
			V2Button
		},
		props: {
			currentPage: {
				type: Number,
				default: 1
			},
			totalPages: {
				type: Number,
				default: 1
			}
		},
		data: () => ({
			idealNumberOfPagesToShowEitherSideOfCurrentPage: 3
		}),
		computed: {
			getFirstPage () {
				return 1;
			},
			getLastPage () {
				return this.totalPages;
			},
			getShowFirstPage () {
				return this.currentPage !== 1;
			},
			getShowLastPage () {
				return this.currentPage !== this.totalPages;
			},
			getPreviousPage () {
				if (this.currentPage === 1) {
					return false;
				}
				return this.currentPage - 1;
			},
			getNextPage () {
				if (this.currentPage === this.totalPages) {
					return false;
				}
				return this.currentPage + 1;
			},
			getTotalNumberOfPagesToShow () {
				return (this.idealNumberOfPagesToShowEitherSideOfCurrentPage * 2) + 1;
			},
			getNumberOfPagesBeforeLastPage () {
				return this.totalPages - this.currentPage;
			},
			getNumberOfPagesToShowBeforeCurrentPage () {
				if (this.currentPage === 1) {
					return 0;
				}
				// beginning of pagination
				if (this.currentPage < this.idealNumberOfPagesToShowEitherSideOfCurrentPage) {
					return this.currentPage;
				}
				// end of pagination
				if (this.getNumberOfPagesBeforeLastPage <= this.idealNumberOfPagesToShowEitherSideOfCurrentPage) {
					return this.getTotalNumberOfPagesToShow - this.getNumberOfPagesToShowAfterCurrentPage - 1;
				}
				return this.idealNumberOfPagesToShowEitherSideOfCurrentPage;
			},
			getNumberOfPagesToShowAfterCurrentPage () {
				if (this.currentPage === this.totalPages) {
					return 0;
				}
				// beginning of pagination
				if (this.currentPage <= this.idealNumberOfPagesToShowEitherSideOfCurrentPage) {
					return this.getTotalNumberOfPagesToShow - this.currentPage;
				}
				// end of pagination
				if (this.getNumberOfPagesBeforeLastPage <= this.idealNumberOfPagesToShowEitherSideOfCurrentPage) {
					return this.getNumberOfPagesBeforeLastPage;
				}
				return this.idealNumberOfPagesToShowEitherSideOfCurrentPage;
			},
			getPagesBeforeCurrentPage () {
				const numPages = this.getNumberOfPagesToShowBeforeCurrentPage;
				return new Array(numPages).fill().map((val, key) => {
					return key + 1;
				}).reverse().map((number) => {
					const pageNumber = this.currentPage - number;
					if (pageNumber <= 0) {
						return false;
					}
					return pageNumber;
				}).filter(Boolean);
			},
			getPagesAfterCurrentPage () {
				const numPages = this.getNumberOfPagesToShowAfterCurrentPage;
				return new Array(numPages).fill().map((val, key) => {
					return key + 1;
				}).map((number) => {
					const pageNumber = this.currentPage + number;
					if (pageNumber > this.totalPages) {
						return false;
					}
					return pageNumber;
				}).filter(Boolean);
			},
			getSurroundingPages () {
				return [
					...this.getPagesBeforeCurrentPage,
					this.currentPage,
					...this.getPagesAfterCurrentPage
				];
			},
			showPagination () {
				return this.getPreviousPage || this.getNextPage;
			}
		},
		methods: {
			getIsCurrentPage (page) {
				return page === this.currentPage;
			},
			paginate (page) {
				window.scrollTo({ top: 0, behavior: 'smooth' });
				this.$emit('paginate', page);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='pagination'] {
		display:flex;
		align-items:center;
		justify-content:center;
		width:100%;

		.btn {
			&:first-child {
				margin-right:rem(12);
			}
			&:last-child {
				margin-left:rem(12);
			}
		}

		[data-element='pages'] {
			display:flex;
			align-items:center;
			padding:0 rem(12);

			[data-element='page'] {
				margin:0 rem(4);
				cursor:pointer;
				user-select:none;

        @include font(18px, $c-darkest-navy, 600, 24px);

				&[data-current] {
					text-decoration:underline;
				}
				&:first-child {
					margin-left:0;
				}
				&:last-child {
					margin-right:0;
				}
			}
		}
	}

</style>
